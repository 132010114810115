import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import ElementUI from "element-plus";
import "element-plus/lib/theme-chalk/index.css";
import "./assets/css/index.css";

// Vue.prototype.$notify = ElementUI.Notification
// Vue.prototype.$message = ElementUI.Message
// Vue.prototype.$confirm = ElementUI.MessageBox
const app = createApp(App);

console.log(process.env)


app.use(ElementUI, { size: "mini" });
app.use(router).mount("#app");
