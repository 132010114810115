<template>
  <router-view></router-view>
</template>
<style lang="stylus">
* {
  margin: 0;
  padding: 0;
}

html {
  overflow scroll
}

html, body {
  font-size: 12px;
  height 100%;
  -webkit-overflow-scrolling: auto;
  // overflow-y: scroll;
}
</style>
